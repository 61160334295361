.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  backdrop-filter: blur(4px) brightness(90%) contrast(110%);
  pointer-events: none;
  contain: strict;

  &[data-is-static='true'] {
    backdrop-filter: blur(20px) brightness(90%) contrast(110%);
    position: static;
    padding: var(--spacing-20) 0;
    contain: initial;
    background: none;

    @media (--mobile) {
      padding: 0;
    }

    & .container {
      position: static;
      transform: none;
      max-height: none;
      overflow: visible;
    }
  }

  &[data-visible='true'] {
    opacity: 1;
    visibility: visible;
    pointer-events: all;

    :is(body, html):has(&) {
      overflow: clip;
    }

    @starting-style {
      opacity: 0;
      transform: scale(0.95);
    }
  }

  &[data-visible='true'] .container {
    transform: translateY(0);
    opacity: 1;
  }
}

.container {
  background-color: var(--color-championship-white);
  width: 100%;
  max-height: 90vh;
  max-width: 800px;
  opacity: 0;
  display: flex;
  flex-direction: column;

  @media (--mobile-tablet) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: initial;
    transform: translateY(20px);
    max-height: 100%;
    height: 100%;
  }
}

.noTransition {
  transform: none;
  transition: 0s;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--spacing-10);
  border-bottom: 1px solid var(--color-tarmac-grey-50);
  height: 64px;
  font-size: 20px;
  flex: 0 0 64px;

  & h5 {
    line-height: 1;
    padding: 0;
    margin: 0;
    font-size: 20px;
  }
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.content {
  padding: 0 var(--spacing-5) var(--spacing-5) var(--spacing-5);
  margin-bottom: 125px;
  display: grid;
  gap: var(--spacing-4);
  flex: 1 1 auto;
  overflow: scroll;

  & h4,
  & h5,
  & h3 {
    margin: 0;
  }

  @media (--tablet) {
    margin-bottom: 100px;
    padding: 0 var(--spacing-6) var(--spacing-5) var(--spacing-6);
  }

  @media (--tablet-l) {
    align-items: center;
    padding: var(--spacing-5) var(--spacing-10) var(--spacing-5) var(--spacing-10);
  }
}

.noFooter {
  margin-bottom: 32px;
}

.fieldGroup {
  gap: 12px;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  margin: var(--spacing-5) 0 0;

  @media (--tablet) {
    grid-template-columns: 1fr 1fr;
  }
}

.field {
  &[data-width='full'] {
    grid-column-start: 1;
    grid-column-end: 2;

    @media (--tablet) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  &[data-width='half'] {
    @media (--tablet) {
      grid-column: auto;
    }
  }
  &[data-width='double'] {
    grid-column-start: 1;
    grid-column-end: 2;
    max-width: calc(50% - 6px);

    @media (--mobile-s) {
      grid-column-start: 1;
      grid-column-end: 2;
      max-width: unset;
    }
    @media (--tablet) {
      grid-column-end: 3;
    }
  }
}

.enrichmentHeader {
  span {
    font-weight: normal;
  }
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
}

.modalFooter {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: var(--spacing-6);
  background-color: var(--color-championship-white);
  box-shadow: 0px 0px 13px 0px rgba(38, 38, 38, 0.14);
  z-index: 9999;

  @media (--mobile) {
    padding: var(--spacing-3) var(--spacing-6) var(--spacing-6);
  }

  @media (--tablet-l) {
    padding: var(--spacing-3) var(--spacing-10) var(--spacing-6);
  }
}

.statusWrapper {
  padding: var(--spacing-8) var(--spacing-10) var(--spacing-12);
  display: flex;
  flex-direction: column;
  padding-top: var(--spacing-8);
  place-items: center;
  gap: 24px;
  text-align: center;
}

.reportStatusWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  & button {
    margin-top: var(--spacing-8);
  }
  > div {
    width: 100%;
  }
}
.resultHeader {
  && {
    margin: 0;
  }
}
