.fullAddress {
  position: relative;
  & textarea {
    color: var(--neutral-700) !important;
  }
}

.editButton {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--neutral-700);
  min-height: var(--button-small-height);
  padding: var(--spacing-1) var(--spacing-3);
}

.addressFields {
  display: grid;
  gap: var(--spacing-2);
}

.footer {
  display: flex;
  align-items: center;
}

.footerButton {
  padding: 0 var(--spacing-1) 0 0;
  color: var(--neutral-700);
  min-height: var(--button-small-height);
}

.footerText {
  font-size: 12px;
}

.wrapper {
  position: relative;
}

.suggestionsWrapper {
  background-color: #fff;
  border: 1px solid var(--color-neutral-700);
  border-top: 1px solid var(--color-neutral-100);
  overflow: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 48px;
  max-height: 250px;
  z-index: 1000;
  width: 100%;
}

.suggestions {
  width: calc(200% + 4px);
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  transition: transform 0.3s ease-in-out;
  width: 50%;

  &:first-child {
    float: left;
  }

  &:last-child {
    float: right;
  }

  &[data-is-open='true'] {
    transform: translate(-100%);
  }

  & li {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--spacing-2);
    padding: var(--spacing-2) var(--spacing-4);

    &:hover {
      background-color: var(--color-neutral-50);
    }
  }
}

.field {
  grid-column-start: span 1;

  &[data-width='full'] {
    grid-column-start: 1;
    grid-column-end: 3;

    @media (--mobile-s) {
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }

  &[data-width='half'] {
    grid-column-start: 1;
    grid-column-end: 2;

    grid-column-end: 3;
    max-width: calc(50% - 6px);

    @media (--mobile-s) {
      grid-column-start: 1;
      grid-column-end: 2;
      max-width: unset;
    }
  }

  &[data-width='double'] {
    @media (--mobile-s) {
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }
}
